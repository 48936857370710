import { useState } from 'react';
import './App.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function Jackpot() {
    
    const [played, setPlayed] = useState(false);
    const [gain, setGain] = useState(null);
    
    const [bacgroundPosition1, setBackgroundPosition1] = useState(0);
    const [bacgroundPosition2, setBackgroundPosition2] = useState(0);
    const [bacgroundPosition3, setBackgroundPosition3] = useState(0);

    const { lang } = useParams();
    
    const loadGains = async () => {
        setPlayed(true);
        try {
            const formData = new URLSearchParams();
            formData.append('input[game_id]', '72031ddf-ff69-4a77-834e-3e095eea82d3');
            formData.append('input[lang]', lang);
            formData.append('input[player_data]', JSON.stringify({}));  // Si vous avez des données supplémentaires
            
            const response = await axios.post('https://api-gain.web-treeosk.online/play', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            setTimeout(()=>{
                setGain(response.data.data); // Traiter la réponse si succès
            }, 8600);
            switch(response.data.data.id){
                case '93d44b95-51f4-43ea-a992-62a55a97e3cb':
                animationLoose();
                break;
                case '2e520a90-7750-464e-9de5-6ed72398b871':
                animationTrousse();
                break;
                case '9697f8ca-8911-4f2e-a0ec-a74afd0506bc':
                animationGel();
                break;
                case '76996d5f-eabc-441c-a973-db74f02efd41':
                animationWecandoo();
                break;
                default:
                break;
            }
        } catch (error) {
            console.error("Erreur lors de l'appel API:", error); // Capturer et loguer l'erreur
        }
    };  
    
    const animationLoose = () => {
        setBackgroundPosition1(randomInt(500, 800));
        setBackgroundPosition2(randomInt(500, 800));
        setBackgroundPosition3(randomInt(500, 800));
    }
    
    const animationTrousse = () => {
        setBackgroundPosition1(696);
        setBackgroundPosition2(694);
        setBackgroundPosition3(696);
    }
    
    const animationGel = () => {
        setBackgroundPosition1(720);
        setBackgroundPosition2(718);
        setBackgroundPosition3(720);
    }
    
    const animationWecandoo = () => {
        setBackgroundPosition1(708);
        setBackgroundPosition2(706);
        setBackgroundPosition3(708);
    }
    
    return (
        <>
        <div className="App" style={{width: '100%',height: '1780px', position: 'fixed', zIndex: 300}}>
        </div>
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', height: '480px', marginTop: '480px', zIndex: 50, position: 'fixed', width: '460px', left: '290px'}}>
        <div className='lamelle lam1' style={{height: '100%', backgroundSize: 'cover', backgroundPositionY: bacgroundPosition1 + '%'}}>
        
        </div>
        <div className='lamelle lam2' style={{height: '100%', backgroundSize: 'cover', backgroundPositionY: bacgroundPosition2 + '%'}}>
        
        </div>
        <div className='lamelle lam3' style={{height: '100%', backgroundSize: 'cover', backgroundPositionY: bacgroundPosition3 + '%'}}>
        
        </div>
        </div>
        {
            <div className='bg' style={{opacity: (gain) ? 1 : 0,transitionDuration: '1000ms',display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', height: '500px', marginTop: '480px', zIndex: 800, position: 'fixed', width: '460px', left: '280px', backgroundSize: 'containt', background: (gain) ? 'url(' + gain.uri + ')' : 'white'}}>
            
            </div>
        }
        {
            !played ?
            <button onClick={loadGains} className='pulse' style={{position: 'absolute', width: '300px', fontSize: '48px', border: 'solid 6px white', background: 'black', color: 'white', left: '370px', padding: '20px', top: '700px', zIndex: 1000}}>
            JOUEZ!
            </button>
            :
            <></>
        }
        </>
    );
}

function randomInt(min, max){
    return Math.floor(Math.random() * (max - min) + min);
}

export default Jackpot;